<template>

  <div class="body" id="download"
       style="margin:0;padding:0;width: 100%; background-color: #FFFFFF;font-size: 12px; font-family: Raleway; min-height: 942px; ">
    <div class="header"
         style="background-color: #DDDDDD; color:#000; display: inline-block; width:100%; margin-bottom: 0px; min-height: 255px; ">
      <div class="row pheader" style="margin-top: 10px;  margin-bottom: 100px;">


        <div class="col-md-12 pheader-content-intro" style="margin-top: 50px; margin-left: 50px">

          <div class="column" style="float: left;flex: 50%;">

            <div
                style="margin-left:10px; margin-top: 5px; position: relative; display: inline-block; max-width: 250px; vertical-align: top;">

              <strong style="font-size: 16px;">Address</strong><br>
              <span style="word-wrap: break-word;">Orko Health Ltd., <br> YN Centre, House 6/A, Road 113, 2nd floor, Gulshan Avenue <br>Phone: +81 720 2212 <br>Email: ask@myorko.com</span>
            </div>
          </div>

          <div class="column" style="float: left;flex: 40%;">
            <h1 style="white-space: nowrap; white-space: nowrap; font-size: 20px; margin-left: 25%;">Transaction
              Statement</h1>
          </div>
          <div class="column"
               style="float:right; margin-right: 20px; display: inline-block; flex: 50%; padding-bottom: 7%;">
            <div
                style=" background-size:contain; background-repeat:no-repeat;">
              <img src="../../../assets/img/logo.svg" alt="" style="float: right; width: 140px; margin-right: 60px;">
            </div>
            <br>
            <span style="font-weight:bold; color: #F9A91A;">Connect. Care</span>

          </div>

        </div>


      </div>

    </div>

    <div class="col-md-12 pheader-secondary" style="background-color: #F9A91A;
                display: inline-block; margin-top:-70px ;margin-bottom: 0px;
                width:100%; padding:0;min-height: 80px; border-radius: 35px 35px 0px 0px">
      <div style="margin-top: 15px;margin-bottom: 50px;">
        <div class="column" style="float: left;flex: 70%; width: 70%">
          <span style="margin-top: 55px; margin-left: 60px;"> <strong>Name: </strong> {{ user.fullname }}</span>
        </div>
        <div class="column" style="float: right;flex: 30%;width: 30%">

          <!--          <span style="float: left; margin-left: 10%;"><strong>User type: </strong> {{ user.user_type }}</span>-->

        </div>
      </div>
    </div>

    <div class="col-md-12 pheader-secondary" style=" margin-top:-30px ;background-color: #ffffff;
                    margin-left: 0px; margin-right:0px; margin-bottom: 0px; display: inline-block; width:100%;
                    padding:0px; border-radius: 30px 30px 0px 0px">
      <div style="margin-left: 100px; display: flex; margin-top: 20px">
        <div class="column" style="float: left; margin-left: -3rem;">
          <!--          <p>Statement from: <b>Form date</b> to <b> to date</b></p>-->
          <table class="table table-bordered" style="width:100%;">
            <thead style="">
            <tr>
              <th>Trxn date</th>
              <th style="width: 35rem;">Description</th>
              <th>Debit</th>
              <th>Credit</th>
              <th>Balance</th>
            </tr>
            </thead>
            <tbody>

            <tr v-for="(item, index) in items" :key="index">
              <td>

                {{ item.transaction_date }}

              </td>
              <td>{{ item.description }}</td>
              <td>{{ item.debit }}</td>
              <td>{{ item.credit }}</td>
              <td>{{ item.balance }}</td>
            </tr>

            <tr>
              <td>Total</td>
              <td></td>
              <td>{{ totalDebit }}</td>
              <td>{{ totalCredit }}</td>
              <td>
                {{ totalBalance }}
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>

    </div>

    <!--     Footer -->
    <div style="">
      <div
          style="padding:0px; margin-top: 10px;  margin-left: 20px; margin-right: 20px;">
        <div class="column" style="margin-top: 5px;display: flex;justify-content: center;">
          <span style="float: left; "><strong>N.T:</strong>
                This Statement is auto generated by Orko Health Ltd.
          </span>
          <span style="float: left; margin: 0% 0% 0% 15%;">
                <strong>Statement generation Date:</strong>
               {{ new Date() | moment("dddd, MMMM Do YYYY") }}
        </span>
        </div>
      </div>
      <div style="padding:0px; margin-left: 0px;">
        <div
            style="display:inline; float: left; flex: 50%; font-size:  20px; text-align: left; width: 50%; color: #7A7A7A; margin-top: -2px; margin-left: 70px;">

        </div>
        <div style="float: right; width: 140px; margin-right: 60px;">
          <img src="../../../assets/img/logo.svg" alt="" style="height: 50px">
          <p style="margin-left: 20px;">Connect.Care</p>

        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "TransactionStatementPdf",
  props: {
    items: {},
    user: {},
  },
  computed: {
    totalDebit() {
      if (this.items) {
        let debitTotal = this.items.reduce(function (sum, item) {
          return sum += item.debit;
        }, 0);
        return debitTotal
      }
      return 0;
    },
    totalCredit() {
      if (this.items) {
        let creditTotal = this.items.reduce(function (sum, item) {
          return sum += item.credit;
        }, 0);
        return creditTotal;
      }

      return 0;
    },
    totalBalance() {
      if (this.items) {
        let balanceTotal = this.items.reduce(function (sum, item) {
          return sum += item.balance;
        }, 0);
        return balanceTotal;
      }
      return 0;
    },
  },
}
</script>

<style scoped>
.body {
  height: 842px;
  width: 595px;
  margin: 50px;
}

#download {
  height: 842px;
  width: 595px;
  margin-left: auto;
  margin-right: auto;
}

thead th {
  text-align: center;
}

tbody td {
  /*height: 3rem;*/
  text-align: center;
}
</style>